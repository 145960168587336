import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useLocation } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

function NavBarLink({ link, title, blurb }) {
  const [chevronHover, setChevronHover] = useState("opacity-0");
  const [chevronSize, setChevronSize] = useState(35);

  const handleMouseOver = () => {
    setChevronHover("opacity-100");
  };
  const handleMouseLeave = () => {
    setChevronHover("opacity-0");
  };

  const linkSelect = useLocation().pathname === '/' ? `#${link}` : `/#${link}`

  // Dynamically adjust the chevron size based on the window width
  useEffect(() => {
    if (window.innerWidth <= 640) {
      setChevronSize(35);
    } else if (window.innerWidth <= 768) {
      setChevronSize(45);
    } else if (window.innerWidth <= 1024) {
      setChevronSize(55);
    } else if (window.innerWidth > 1024) {
      setChevronSize(65);
    }
    // eslint-disable-next-line
  }, [window.innerWidth]);

  return (
    <div className="my-4 md:my-8">
      <HashLink className="" to={`${linkSelect}`}>
        <h4
          className="text-3xl md:text-5xl lg:text-6xl xl:text-7xl hover:text-gold-plate hover:-translate-x-6 transition-all"
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          {title}
          <span
            className={`${chevronHover} absolute chevron text-gold-plate transition-opacity`}
          >
            <IoChevronBackOutline strokeWidth={2} size={chevronSize} />
          </span>
        </h4>
      </HashLink>
      <p className="navFullScreen-text">{blurb}</p>
    </div>
  );
}

export default NavBarLink;
