import React from "react";

function DesignCapabilityCard({ heading, blurb, icon }) {
  // Todo: look at using outline or box-sizing to avoid the slight jiggle of increasing border sizes.
  return (

      <div
        className="
        lg:basis-5/12
        xl:basis-1/3
        hover:text-slate-dark
        bg-slate-midnight hover:bg-snow-light border border-l-2 border-b-2 rounded-tr-3xl rounded-bl-3xl 
        border-snow-light hover:border-2 hover:border-l-gold-plate hover:border-b-gold-plate
        hover:cursor-pointer
        px-4 pb-8 md:px-8 md:pb-16 transition-colors
        mr-4 mt-4 box-border">
        <div className="text-gold-plate my-8">{icon}</div>
        <h4 className="text-lg sm:text-xl md:text-2xl my-4 font-medium">
          {heading}
        </h4>
        <p className="text-sm md:text-base">{blurb}</p>
      </div>


  );
}

export default DesignCapabilityCard;
