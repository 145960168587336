import React from "react";
import SectionHeader from "../components/SectionHeader";
import SectionDivider from "../components/SectionDivider";
import Button from "../components/Button";

function DevProcess() {
  return (
    <section id="dev-process">
      <article className="mb-8 lg:w-2/3">
        <SectionDivider text="Where I fit in" />
        <SectionHeader heading="Development Process" />
        <div className="pb-4">
          <p className="text-lg md:text-xl mb-4">
          Product development is a multi-disciplinary activity requiring many different individuals with differing skill sets working together.
          Having worked extensively with industrial designers, mechanical engineers, hardware developers and production teams, I’m comfortable slotting in exactly where you need me to be.
          </p>
          {/* <p className="text-lg md:text-xl mb-4">
            I can’t do everything, but I work with everyone that can.
          </p> */}
        </div>
      </article>
      <Button
        link="/dev-process"
        hashLink="false"
        text="Read more"
        filled="false"
      />
    </section>
  );
}

export default DevProcess;
