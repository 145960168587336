import React from "react";
import Button from "../components/Button";
// import HeroImagesGrid from '../components/HeroImagesGrid';
import HeroImagesGrid_2 from "../assets/images/hero_images_grid_2.png";

function Hero() {
  return (
    <section className="hero-section relative mt-16 pb-44 lg:mt-22 lg:pb-44">
      <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl py-4 w-3/4 sm:mb-8">
        End to End Embedded Systems Development
      </h1>
      <p className="text-lg sm:text-xl md:text-2xl py-4 md:my-4 hero-blurb w-3/4 sm:w-1/2 sm:mb-8">
      Providing embedded systems development contracting to help hardware companies solve difficult problems, get unstuck and build exceptional products.
      </p>
      <div className="flex flex-wrap sm:flex-nowrap sm:mb-8">
        <Button
          link="#latest-works"
          hashLink="true"
          text="My Works"
          filled={true}
          type={false}
        />
        <Button
          link="#services"
          hashLink="true"
          text="My Services"
          filled={false}
          type={false}
        />
      </div>
      <div className="flex justify-start w-1/2 my-10 xl:w-1/3 text-gray-300">
        <p className="mr-10 md:mr-4 w-28 border-b-2 border-gray-300 text-sm sm:text-base md:text-lg sm:mb-8">
          Embedded Firmware
        </p>
        <p className="mr-10 md:mr-4 w-28 border-b-2 border-gray-300 text-sm sm:text-base md:text-lg sm:mb-8">
          Hardware Design
        </p>
        <p className="mr-10 md:mr-4 w-28 border-b-2 border-gray-300 text-sm sm:text-base md:text-lg sm:mb-8">
          Low Volume Assembly
        </p>
      </div>
      <img
        className="hero-images-grid-bg-gradient absolute top-0 right-0 lg:-right-20 -z-20 sm:mb-8"
        src={HeroImagesGrid_2}
        alt="Hero Images Grid"
      />
      <div className="mask-overlay absolute -z-20"></div>
    </section>
  );
}

export default Hero;
