import React from "react";

function SectionHeader({ heading }) {
  return (
    <div>
      <h2 className="text-3xl sm:text-4xl lg:text-5xl py-4 my-8">{heading}</h2>
    </div>
  );
}

export default SectionHeader;
