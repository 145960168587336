import React, { useEffect, useState } from "react";
import SectionHeader from "../components/SectionHeader";
import SectionDivider from "../components/SectionDivider";
import LatestWorksGridItem from "../components/LatestWorksGridItem";

import placeholderBg from '../assets/images/latest-works-backgrounds/bg-placeholder-3.png'
import antennaMatching from '../assets/images/latest-works-backgrounds/antenna-matching.png'
import tetherEnviroq from '../assets/images/latest-works-backgrounds/tether_enviroq.png'
import canmaster from '../assets/images/latest-works-backgrounds/canmaster_wide.png'
import ethernet from '../assets/images/latest-works-backgrounds/ethernet.jpg'
import supercap from '../assets/images/latest-works-backgrounds/supercap.jpg'
import flowmeter from '../assets/images/latest-works-backgrounds/flowmeter.jpg'
import rfProgrammer from '../assets/images/latest-works-backgrounds/rf-programmer.jpg'
import snapperMx8 from '../assets/images/latest-works-backgrounds/snapper-mx8.jpg'


const LATEST_WORKS = [
  {
    heading: "Gould Instruments Flowmeter",
    blurb1: "An industrial flow and pressure meter, this device needed to power and interface with external industrial sensors, while providing an easy user interface for measurement and calibration. It features a graphical LCD display and firmware updates via USB.",
    blurb2: "I did the hardware and firmware development and managed the project, including organising contractors for mechanical and industrial design. I did a small volume manufacturing run top provide the customer with their first batch of",
    size: 3,
    bgImg: flowmeter,
    iconsPng: ['ST Micro'],
    iconsReact: ['Battery Management', 'USB', 'Signal Processing']
  },
  {
    heading: "Compact Programming Jig",
    blurb1: "This board replaced an existing end-of-line programming fixture with a single board solution providing UART, JTAG, and power via USB-C.",
    blurb2: "I worked closely with the client's software team to develop the hardware based on their requirements, as well as doing a small assembly run.",
    size: 4,
    bgImg: rfProgrammer,
    iconsPng: [],
    iconsReact: ['USB']
  },
  {
    heading: "DesignA Snapper MX8",
    blurb1: "This product was a SOM, based around an i.MX 8 application processor. This design had many high speed digital interfaces, which needed to be incorporated, including: LPDDR4 memory, EMMC, WiFi, LVDS, HDMI, USB3, RGMII, and more.",
    blurb2: "I did the hardware design and layout, working with the DesignA team who provided requirements. Yielding an improvement in performance and capability, while still maintaining backwards compatability with a previous product was the main challenge.",
    size: 5,
    bgImg: snapperMx8,
    iconsPng: ['NXP Semi'],
    iconsReact: ['USB', 'High speed']
  },
  {
    heading: "Supercap Charger",
    blurb1: "For an application where both peak and ultra low standby currents were required, I designed a super capacitor pack to replace the device's existing battery pack.",
    blurb2: "This device trickle charges a supercapacitor bank from high density lithium cells. This board implements balancing and discharge protection to prevent supercap degradation. A coulomb counter was also implemented to indicate consumed charge to the downstream device.",
    size: 5,
    bgImg: supercap,
    iconsPng: ['ST Micro'],
    iconsReact: ['Battery Management']
  },
  {
    heading: "RF Antenna Matching",
    blurb1: "After a mechanical change, this product required a new antenna matching circuit to optimize radio performance.",
    blurb2: "The radio operated in the 900MHz range, had a T matching network provisioned for tuning. I used my vector network analyzer to evaluate the existing match, and iterated on it to achieve a new match. The match was then tested on several units to confirm stability of the solution.",
    size: 3,
    bgImg: antennaMatching,
    iconsPng: [],
    iconsReact: ['RF Design']
  },
  {
    heading: "Heater Processor Refit",
    blurb1: "An existing product in need of component alternatives due to sourcing issues.",
    blurb2: "I researched an alternative processor, with the goal of requiring no copper change on the PCB. Once selected, I recompiled and optimized the firmware to operate on the new target processor.",
    size: 3,
    bgImg: placeholderBg,
    iconsPng: ['ST Micro'],
    iconsReact: [],
  },
  {
    heading: "Serial to Ethernet Adaptor",
    blurb1: "An internal product development, this adaptor was created to provide an ethernet interface for equipment with a TTL serial interface.",
    blurb2: "Based around the W5500 IC, it implements TCP sockets for remote serial communication, a UDP broadcast mechanism for device discovery, and DHCP IP assignment.",
    size: 3,
    bgImg: ethernet,
    iconsPng: ['ST Micro', 'Ethernet'],
    iconsReact: []
  },
  {
    heading: "Tether EnviroQ",
    blurb1: "An indoor environment monitoring system for building health monitoring, integrating CO2, temperature, humidity, pressure, light, and SPL sensors, and utilizing the Sigfox network to report device status to a custom cloud backend.",
    blurb2: "Taking responsibility for maintenance and ongoing product improvements, I implemented new low power optimisations, sound pressure level monitoring and as well as undertaking multiple revisions for hardware and firmware for component obsolescence.",
    size: 5,
    bgImg: tetherEnviroq,
    iconsPng: ['ST Micro', 'SigFox', 'LORAWAN'],
    iconsReact: ['Battery Management'],
    bgPosition: 'bg-bottom'
  },
  {
    heading: "CANmaster",
    blurb1: "An internal product development, CANmaster was created to be a more robust USB to CAN adapter.",
    blurb2: "As a USB Communications Device Class to CAN serial converter with configurable CAN bus terminator and bitrate, it works with both Windows and Linux without the need for  custom driver developments.",
    size: 3,
    bgImg: canmaster,
    iconsPng: ['ST Micro', 'CAN Bus'],
    iconsReact: ['USB']
  },
  {
    heading: "IoT Bait Monitor",
    blurb1: "A remote IoT predator bait monitoring system with strong low power requirements. I had responsibility for the firmware as well as a PCB revision.",
    blurb2: "Key areas tech areas included low power optimisation, incorporating NB-IoT and Sigfox comms, as well various sensors including Laser ToF, PIR, and impact detection via accelerometers.",
    size: 4,
    bgImg: placeholderBg,
    iconsPng: ['ST Micro', 'SigFox', 'NB IoT'],
    iconsReact: ['Battery Management']
  },
  {
    heading: "Industrial Motor Soft-Starter",
    blurb1: "A PLC controlled industrial conveyor belt controller used in airport and logistics sectors. The system was designed specifically to be plug-and-play with fast, more cost effective on site installation in mind.",
    blurb2: "In working with the client team, I was responsible for the entirety of firmware development including two-phase soft-starting algorithms, current monitoring with fault detection and USB mass storage, as well as integrating both AS-I plus PROFInet and creating a custom bootloader.",
    size: 5,
    bgImg: placeholderBg,
    iconsPng: ['ST Micro', 'Ethernet'],
    iconsReact: ['Control Systems', 'USB'],
  },
  {
    heading: "Mobile Scale Monitor",
    blurb1: "A Bluetooth LE monitoring device for sets of mobile scales, reporting measured readings back to a BLE base station.",
    blurb2: "I was responsible for developing the firmware, BLE based reporting and configuration, load cell and temperature sensor  integration.",
    size: 3,
    bgImg: placeholderBg,
    iconsPng: ['Nordic'],
    iconsReact: ['Battery Management', 'Bluetooth'],
  },
  {
    heading: "IoT ToF Ranging Sensors",
    blurb1: "An IoT level measurement device utilizing ultrasonic time-of-flight sensors to measure distance.",
    blurb2: "As an IoT device with a five year installed lifetime, low power consumption was a critical factor to factor into software design. I was responsible for firmware incorporating lower power optimisations, battery conditioning, Sigfox comms, ultrasonic ranging algorithms as well as creating production test fixtures.",
    size: 3,
    bgImg: placeholderBg,
    iconsPng: ['ST Micro', 'SigFox', 'LORAWAN'],
    iconsReact: ['Battery Management', 'Signal Processing'],
  },
]


function LatestWorks() {

  const [layout, setLayout] = useState("grid-container");

  function handleResize() {
    if (window.innerWidth <= 1024) {
      setLayout("");
    } else {
      setLayout("grid-container");
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
  }, []);

  const columns = 3;
  var row_depth = [0, 0, 0];

  function createGridItem(item) {

    let column = 0;
    let row_start = row_depth[0];
    for (let col = 1; col < columns; col++) {
      if (row_depth[col] < row_start) {
        row_start = row_depth[col]
        column = col;
      }
    }

    row_depth[column] += item.size

    return <LatestWorksGridItem
      columnStart={column + 1}
      rowStart={row_start + 1}
      rowEnd={row_start + item.size + 1}
      heading={item.heading}
      blurb1={item.blurb1}
      blurb2={item.blurb2}
      bgImg={item.bgImg}
      iconsPng={item.iconsPng}
      iconsReact={item.iconsReact}
      bgPosition={item.bgPosition}
    />
  }

  return (
    <section id="latest-works">
      <article className="mb-8">
        <div id="works-headings">
          <SectionDivider text="What I've worked on" />
          <SectionHeader heading="Latest Works" />
        </div>
        {/* Should be a grid on larger screens */}
        <div id="" className={`${layout} pt-10`}>
          { LATEST_WORKS.map( item => createGridItem(item)) }
        </div>
      </article>
    </section>
  );
}

export default LatestWorks;
