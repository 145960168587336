import React from "react";

function SectionDivider({ text }) {
  return (
    <div className="flex flex-wrap flex-row-reverse sm:flex-nowrap justify-end content-center my-4">
      <span className="ml-4 inline text-gold-plate text-sm sm:text-base md:text-lg lg:text-xl italic font-bold self-center">
        {text}
      </span>
      <svg
        className="inline-block w-20"
        viewBox="0 -0.5 20 1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0" y1="0" x2="40" y2="0" stroke="#DCA54C" strokeWidth="1" />
      </svg>
    </div>
  );
}

export default SectionDivider;
