import React from "react";

import Hero from "../sections/Hero";
import Services from "../sections/Services";
import DevProcess from "../sections/DevProcess";
import LatestWorks from "../sections/LatestWorks";
import AboutMe from "../sections/AboutMe";
import Contact from "../sections/Contact";

function Landing() {
  return (
    <div className="mx-auto">
      <Hero />
      <Services />
      <DevProcess />
      <LatestWorks />
      <AboutMe />
      <Contact />
    </div>
  );
}

export default Landing;
