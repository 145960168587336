import React from "react";

function Footer() {
  return (
    <footer>
      <div className="text-center text-sm sm:text-base p-8 text-slate-300 bg-slate-dark-gradient">
        <p>TL Embedded - All Rights Reserved 2022</p>
        <a className="inline-block  text-gray-500 mt-4" href="https://www.alan-povall.com" rel="noreferrer" target="_blank">Website by Alan Povall</a>
      </div>
    </footer>
  );
}

export default Footer;
