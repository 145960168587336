import React from "react";
import SectionDivider from "../components/SectionDivider";
import SectionHeader from "../components/SectionHeader";
import DesignCapabilityCard from "../components/DesignCapabilityCard";
import ServicesBackground from "../assets/images/services-background.png";
import {
  IoHardwareChipOutline,
  IoRadioOutline,
  IoBatteryDeadOutline,
} from "react-icons/io5";
import { MdOutlineSpeakerPhone } from "react-icons/md";
import { FaUsb, FaWrench } from "react-icons/fa";
import { TiArrowMinimise } from "react-icons/ti";
import { VscFileBinary } from "react-icons/vsc"
import { TbWaveSawTool } from "react-icons/tb"
import {BsGear} from "react-icons/bs"

function Services() {

  return (
    <section id="services" className="overflow-x-visible">
      <div
        className="bg-contain bg-no-repeat "
        style={{ backgroundImage: `url(${ServicesBackground})` }}
      >
        <SectionDivider text="What's on offer" />
        <article className="backdrop-blur-sm">
          <SectionHeader heading="My Services" />
          <div className="lg:w-2/3">
            <p className="text-lg md:text-xl mb-4">
              Having worked extensively in a new product development consulting environment, I’ve developed strong capability in both hardware and firmware development to help clients go from initial concept to their first batch of product.
            </p>
            <p className="text-lg md:text-xl mb-4">
              Whether you need help bringing a new product to life, have an existing product in need of support or team in need of additional expertise, I can slot in where needed.
            </p>
          </div>

          <h3 className="text-2xl sm:text-3xl md:text-4xl mt-16 mb-4">
            Design Capability
          </h3>
          <div className="flex flex-wrap xl:flex-nowrap">
            <DesignCapabilityCard
              heading="Hardware Design"
              blurb="Assist with schematic capture as well as PCB design for UHF RF designs, analog, and high speed digital systems using modern tools including KiCad, Circuit Studio, and Altium."
              icon={<IoHardwareChipOutline size={55} />}
            />
            <DesignCapabilityCard
              heading="Prototype Assembly"
              blurb="I can do small batch assembly to validate prototypes, and get product in the hands of customers as soon as possible."
              icon={<FaWrench size={55} />}
            />
            <DesignCapabilityCard
              heading="Firmware Development"
              blurb="Power your hardware with lightweight and maintainable firmware and drivers, leveraging a variety of my existing libraries to accelerate your development."
              icon={<VscFileBinary size={55} />}
            />
            <DesignCapabilityCard
              heading="Hardware Support"
              blurb="After initial delivery, products still require maintenance and debugging. Well equipped with a range of test gear, I can help pinpoint issues that may span the hardware, software, or physical domains."
              icon={<TbWaveSawTool size={55} style={{strokeWidth:1.5}} />}
            />
          </div>
        </article>

        <article className="mt-16 md:mt-32">
          <h3 className="text-2xl sm:text-3xl md:text-4xl mt-16 mb-4">
            Areas of Expertise
          </h3>
          <div className="systems-grid">
          <DesignCapabilityCard
              heading="Resource Constrained Systems"
              blurb="Embedded systems have unique resource constraints, requiring small, cheap, and low power systems to perform a wide array of functions. Optimizing how individual elements come together - bootloaders, comms stacks, logging frameworks, real-time systems, sensing algorithms and low power sleep modes - is an area I excel in."
              icon={<TiArrowMinimise size={55}/>}
            />
            <DesignCapabilityCard
              heading="Low Power Systems"
              blurb="Ultra low power systems - in the µA ranges -  with multi-year lifetimes require special consideration in terms of hardware and firmware development, including battery chemistry, power supply topology and power management considerations, especially when accounting for intermittent demand for high power sensors and radios."
              icon={<IoBatteryDeadOutline size={55}/>}
            />
            <DesignCapabilityCard
              heading="IoT Communications"
              blurb="Highly experienced in IoT systems & devices, having deployed products into a variety of sectors and worked with numerous radio technologies including SigFox, LoRaWAN, NB-IoT, Bluetooth and custom RF protocols."
              icon={<IoRadioOutline size={55}/>}
            />
            <DesignCapabilityCard
              heading="Sensing"
              blurb="Comfortable with a wide variety of external MEMS sensors, as well as sampling analog data (such as current monitoring & audio), precision timing sensors (such as  infra-red and ultrasound), signal processing and event detection."
              icon={<MdOutlineSpeakerPhone size={55}/>}
            />
            <DesignCapabilityCard
              heading="USB Devices"
              blurb="Considerable capability in developing and debugging USB devices including Communications Devices, MSC, MTP, HID, and composite devices."
              icon={<FaUsb size={55}/>}
            />
            <DesignCapabilityCard
              heading="Control Systems"
              blurb="Real time control of motors and outputs, such as DC and industrial AC motors.
              These have requirements for real time feedback, as well as fault and safety cases."
              icon={<BsGear size={55}/>}
            />
          </div>
        </article>
      </div>
    </section>
  );
}

export default Services;
