import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Button({ link, hashLink, text, filled, type }) {
  const [hoverTranslate, setHoverTranslate] = useState(
    "hover:translate-x-1 hover:-translate-y-1"
  );

  let baseStyle = "";
  let linkStyle = "";
  let bgStyle = "";
  let displayComponent;

  if (filled) {
    baseStyle =
      "bg-gold-plate border-2 border-gold-plate hover:border-r-gold-dark ";
    bgStyle = "bg-gold-highlight";
    linkStyle = "text-slate-midnight";
  } else {
    baseStyle = "bg-slate-midnight border-2 border-gold-plate ";
    bgStyle = "bg-gold-plate";
    linkStyle = "text-gold-plate";
  }

  const handleClick = () => {
    setHoverTranslate("transition-none");
    setTimeout(() => {
      setHoverTranslate("hover:translate-x-1 hover:-translate-y-1");
    }, 120);
  };

  const linkComponent = (
    <div className={`relative`} onClick={handleClick}>
      <div
        className={`${bgStyle} absolute -z-10 h-full rounded-xl w-40 `}
      ></div>
      <div
        className={`${baseStyle} ${hoverTranslate} rounded-xl transition-all cursor-pointer my-2 py-2 px-4 mr-2 w-40 md:my-4 md:py-4 md:px-8 md:mr-4`}
      >
        <p
          className={`no-underline font-bold  ${linkStyle} text-center text-sm`}
        >
          {text}
        </p>
      </div>
    </div>
  );

  if (hashLink) {
    displayComponent = <HashLink to={link}>{linkComponent}</HashLink>;
  } else {
    displayComponent = <Link to={link}>{linkComponent}</Link>;
  }

  return <div className={``}>{displayComponent}</div>;
}

export default Button;
