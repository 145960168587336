import React from "react";
import SectionHeader from "../components/SectionHeader";
import SectionDivider from "../components/SectionDivider";
import ContactForm from "../components/ContactForm";
import Background from "../assets/images/contact_background.png";
import ContactSocialMedia from "../components/ContactSocialMedia";
import { FiGithub, FiLinkedin } from "react-icons/fi";

function Contact() {
  return (
    <section
      id="contact"
      className="w-full bg-cover bg-no-repeat pb-40 mb-0"
      style={{ backgroundImage: `url('${Background}')` }}
    >
      <div className="w-10/12 mx-auto">
        <SectionDivider text="Contact details" />
        <SectionHeader heading="Let's build something together" />
        <div className="flex flex-wrap md:flex-nowrap justify-around md:justify-start">
          <div className="basis-1/5 flex flex-nowrap md:flex-wrap md:flex-col">
            <div className="">
              <ContactSocialMedia
                topline="LinkedIn"
                bottomline="Tim Lamborn"
                link="https://www.linkedin.com/in/tim-lamborn/"
                icon={<FiLinkedin size={35} />}
              />
            </div>
            <div className="">
              <ContactSocialMedia
                topline="Github"
                bottomline="TL-Embedded"
                link="https://github.com/Lambosaurus"
                icon={<FiGithub size={35} />}
              />
            </div>
          </div>
          <div className="">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
