import React, {useState, useRef} from 'react'
import emailjs from '@emailjs/browser';
import RadioCheckbox from './RadioCheckbox'
import PinDot from '../assets/images/pin_dot.png'


function ContactForm() {

  const baseRadioState = {
    "hw-design": false,
    "firmware-design": false,
    "pcb-layout": false,
    "sys-design": false,
    "bringup": false,
    "something-else": false
  }
  const prodTypeBaseState = {
    "new-product": false,
    "existing-product": false
  }

  const form = useRef()
  const [sentState, setSentState] = useState('opacity-0')
  const [errorState, setErrorState] = useState('hidden')
  const [radioSelection, setRadioSelection] = useState(baseRadioState)
  const [prodTypeSelection, setProdTypeSelection] = useState(prodTypeBaseState)
  const [hoverTranslate, setHoverTranslate] = useState("hover:translate-x-1 hover:-translate-y-1")
  
  const handleRadioClick = (e) => {
    setRadioSelection({
      ...baseRadioState,
      [e.currentTarget.id]: true
    })
  }

  const handleProdTypeSelection = (e) => {
    setProdTypeSelection({
      ...prodTypeBaseState,
      [e.currentTarget.id]: true
    })
  }

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setSentState('opacity-0')
    emailjs.sendForm('service_9zhfx1s', 'template_u9x9lnl', form.current, 'c8qYfqow0fs4iAVMI')
      .then((result) => {
          console.log(result.text);
          setSentState('opacity-100')
          form.current.reset()
          setRadioSelection({...baseRadioState})
          setProdTypeSelection({...prodTypeBaseState})
      }, (error) => {
          setErrorState('')
          console.log(error.text);
      });

    setHoverTranslate("transition-none")
    setTimeout(() => {
      setHoverTranslate("hover:translate-x-1 hover:-translate-y-1")
    }, 120)

    setTimeout(() => {
      setErrorState('hidden')
    },5000)
  }


  return (
    <div className='
     relative
      bg-gradient-to-br to-slate-dark from-slate-dark-gradient
      border-t-slate-600 border-r-slate-midnight border-b-slate-midnight border-l-slate-600
      border-2
      py-4
      rounded-xl '>
      <img className='absolute top-4 left-4 w-' src={`${PinDot}`} alt="" />
      <form className='py-8 px-8 md:p-16' ref={form} onSubmit={handleFormSubmit}>
        <div id="names" className='flex justify-around'>
          <div id="" className='w-full mr-4 my-2'>
            <label 
              htmlFor="first-name"
              className='font-bold'
              >First Name</label>
            <input 
              type="text"
              id="first-name"
              name='first-name'
              className='block rounded-lg px-4 w-full py-3 my-2 text-sm'
              placeholder='Ken'
              required
              ></input>
          </div>
          <div id="" className='w-full my-2'>
          <label 
              htmlFor="last-name"
              className='font-bold'
              >Last Name</label>
            <input 
              type="text"
              id="last-name"
              name='last-name'
              className='block rounded-lg px-4 w-full py-3 my-2 text-sm'
              placeholder='Thompson'
              required
              ></input>
          </div>
        </div>
        <div id="" className='w-full mr-4 my-2'>
          <label 
              htmlFor="email"
              className='font-bold'
              >Email Address</label>
            <input 
              type="email"
              id="email"
              name='email'
              className='block rounded-lg px-4 w-full py-3 my-2 text-sm'
              placeholder='ken.thompson@bell-labs.com'
              required
              ></input>
          </div>
          <div id='' className='mb-4 mt-8'>
            <label className='font-bold'> I'm working on...</label>
            <div className="prod-type-grid">
              <RadioCheckbox name="new-product" text="A new product" type="product" checked={prodTypeSelection['new-product']} handleRadioClick={handleProdTypeSelection}/>
              <RadioCheckbox name="existing-product" text="An existing product" type="product" checked={prodTypeSelection['existing-product']} handleRadioClick={handleProdTypeSelection}/>
            </div>
          </div>
          <div id='' className='my-4'>
            <label className='font-bold'> I'd like help with...</label>
            <div className="radio-grid">
              <RadioCheckbox name="hw-design" text="Hardware Design" type="service" checked={radioSelection['hw-design']} handleRadioClick={handleRadioClick}/>
              <RadioCheckbox name="firmware-design" text="Firmware Development" type="service" checked={radioSelection['firmware-design']} handleRadioClick={handleRadioClick}/>
              <RadioCheckbox name="sys-design" text="Systems Design" type="service" checked={radioSelection['sys-design']} handleRadioClick={handleRadioClick}/>
              <RadioCheckbox name="something-else" text="Something else" type="service" checked={radioSelection['something-else']} handleRadioClick={handleRadioClick}/>
            </div>
          </div>
          <div className='w-full'>
            <label className='font-semibold'>Message</label>
            <textarea 
              className='block rounded-lg w-full text-slate-dark text-sm p-4 my-4'
              name="message"
              placeholder="What can I help you with? Give me a brief rundown of what you'd like to discuss."
              rows="6"></textarea>
          </div> 
          <div className='relative z-[1] '>
            <div className={`bg-gold-plate absolute -z-10 h-full rounded-xl w-48`} ></div>
            <button className={`text-gold-plate font-bold bg-slate-dark border-2 border-gold-plate ${hoverTranslate} rounded-xl 
              transition-all cursor-pointer py-2 px-4 mr-2 md:py-4 md:px-8 md:mr-4 w-48`} type='submit'>Message Tim</button>
          </div>
          <div className='my-4'>
            <div className={`${sentState}`}>
                <p className={`absolute float-right transition-opacity text-gold-plate`}>Message sent</p>
            </div>
            <div className={`${errorState} absolute float-right text-xs sm:text-base text-gold-plate`}>
              <p>Uh oh, something went wrong.</p>
              <p>Please try email me directly at <a className='underline' href={`mailto:tim@tlembedded.com?subject=Contact%20from%20TLE%20Website&body=Hi%20Tim,%0A`}>tim@tlembedded.com</a></p>
            </div>
          </div>
      </form>
    </div>
  )
}

export default ContactForm