import Landing from "./pages/Landing";
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './sections/Navbar'
import DevProcessFull from './pages/DevProcessFull'
import Footer from './sections/Footer'

function App() {
  return (
    <main className="text-white">
    <Router>
      <Navbar />
        <Routes>
          <Route path='/' element={<Landing/>}></Route>
          <Route path='/dev-process' element={<DevProcessFull/>}></Route>
        </Routes>
      <Footer />
    </Router>
    </main>
  );
}

export default App;
