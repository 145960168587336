import React, { useEffect, useState } from "react";
import NavBarLink from "./NavBarLink";

function NavFullScreen({ displayState }) {
  const [opacity, setOpacity] = useState("opacity-100");

  useEffect(() => {
    if (displayState === "visible") {
      setOpacity("opacity-100");
    } else {
      setOpacity("opacity-0");
    }
  }, [displayState]);

  return (
    <div
      className={`${displayState} ${opacity} transition-opacity bg-slate-dark w-screen h-screen absolute top-0 right-0 `}
    >
      <div className="mt-20 mr-8 md:mr-16 text-right">
        <NavBarLink
          link="services"
          title="Services"
          blurb="What I can do for you"
        />
        <NavBarLink
          link="dev-process"
          title="Process"
          blurb="Where I can help"
        />
        <NavBarLink
          link="latest-works"
          title="Latest Works"
          blurb="Past projects and experience"
        />
        <NavBarLink
          link="about-me"
          title="About Me"
          blurb="What keeps me going"
        />
        <NavBarLink
          link="contact"
          title="Get in touch"
          blurb="Let's work together"
        />
      </div>
    </div>
  );
}

export default NavFullScreen;
