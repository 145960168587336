import React from "react";
import SectionDivider from "../components/SectionDivider";
import Profile from "../assets/images/about_me_tim_lamborn_profile_bw.jpg";

function AboutMe() {
  return (
    <section id="about-me" className="relative 2xl:w-4/5">
      <SectionDivider text="A bit about me" />
      <div className="w-full relative border-gold-plate rounded-l-xl">
        <img
          src={`${Profile}`}
          className="rounded-md xl:w-2/3 mt-10 xl:mt-20"
          alt="Tim Lamborn Profile"
        />
        <div
          className=" 
          relative
          xl:absolute
          w-11/12 xl:w-1/2 xl:right-10 xl:top-0 bottom-4 md:bottom-10
          bg-slate-medium
          rounded-r-xl
          p-8 xl:p-10 2xl:p-12
          min-h-min
          mx-auto xl:mt-10"
        >
          <div className="flex flex-col">
            <h2 className="text-xl md:text-2xl lg:text-3xl 2xl:text-5xl font-bold mb-8">
               Hey, I'm Tim.
            </h2>
            <p className="mb-4 text-sm lg:text-base font-bold 2xl:text-xl italic">
              Engineer, Artist, Test-Equipment Aficionado
            </p>
            <p className="mb-4 text-sm lg:text-base ">
              As you may have guessed, I’m an embedded engineer that thrives in consulting environments - working closely with clients to help them get unstuck, or push their products to the next stage.
            </p>
            <p className="mb-4 text-sm lg:text-base ">
              Having worked on a variety of projects, from scientific devices to motor controllers, I now spend my time working with highly constrained devices and squeezing unexpected performance out of them - one of many joys of the job. I enjoy working independently as well as slotting in directly with larger teams, where I can fill gaps in expertise. 
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutMe;
