import React from 'react'
import NumberLine from "../assets/images/devProcess/number_line.png"

function ProcessCard({title, blurb, number, imgURL, alignment, highlighted, swap, smallScreen, lastItem}) {

  const image = require(`../assets/images/devProcess/${imgURL}`)

  let numberStyle = ""
  let textStyle = ""
  let position1 = ""
  let position2 = ""
  let position3 = ""
  let textContainer = {...alignment['position1']}
  let numberContainer = {...alignment['position2']}
  let imageContainer = {...alignment['position3']}

  numberStyle = highlighted ? "text-gold-plate" : "text-slate-light"
  textStyle = highlighted ? "" : "text-slate-light"

  let textHTML = (
    <div className={`text-container ${textStyle}`} style={textContainer}>
      <h4 className='text-2xl'>{title}</h4>
      <p>{blurb}</p>
    </div>
  )
  let imageHTML = (
    <div className={`img-container`} style={imageContainer}>
      <img src={image} alt={title} className="mx-auto rounded-xl" />
    </div>
  )
  let numberHTML = (
    <div className={`number-container relative`} style={numberContainer}>
      <div className={`text-2xl bg-slate-dark-gradient p-2 md:p-4 number-style ${numberStyle}`}>{number}</div>
      {!lastItem  ? <img className='absolute left-1/2 h-full' src={`${NumberLine}`} alt="" /> : ""}
    </div>
  )

  if(swap && !smallScreen){
    position1 = imageHTML
    position2 = numberHTML
    position3 = textHTML
  } else if(!swap && !smallScreen){
    position1 = textHTML
    position2 = numberHTML
    position3 = imageHTML
  } else if (smallScreen){
    position1 = textHTML
    position2 = numberHTML
    // position3 = textHTML  
  } 

  return (
    <>
      {position1}
      {position2}
      {position3}
    </>
  )
}

export default ProcessCard