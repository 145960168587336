import React, { useState, useEffect } from "react";
import NavFullScreen from "../components/NavFullScreen";
import { Link } from "react-router-dom";
import Logo from "../assets/images/tlembedded-logo.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

function Navbar() {
  const openButton = <AiOutlineMenu size={45} />;
  const closeButton = <AiOutlineClose size={45} />;

  const [navState, setNavState] = useState("hidden");
  const [zIndex, setZIndex] = useState("z-0");

  const handleClick = () => {
    if (navState === "visible") {
      setNavState("hidden");
    } else {
      setNavState("visible");
    }
  };

  useEffect(() => {
    if (navState === "visible") {
      setZIndex("z-50");
    } else {
      setZIndex("z-0");
    }
  }, [navState]);

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <nav className="sticky top-0 z-10 backdrop-blur-xl">
      {/* Home Link */}
      <Link to="/" onClick={handleScroll}>
        <img
          className="logo-img m-5 inline-block w-10 md:w-14"
          src={`${Logo}`}
          alt="TL Embedded Logo"
        />
        <h4 className="inline-block text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold">
          {" "}
          TL Embedded{" "}
        </h4>
      </Link>
      {/* Nav menu */}
      <div className="absolute top-0 right-0" onClick={handleClick}>
        <div
          className={`hover:cursor-pointer absolute top-4 right-4 ${zIndex}`}
        >
          {navState === "hidden" ? openButton : closeButton}
        </div>
        <NavFullScreen displayState={navState}></NavFullScreen>
      </div>
    </nav>
  );
}

export default Navbar;
