import canGoldPlate from '../images/logos/can-gold-plate.png'
import ethernetGoldPlate from '../images/logos/ethernet-gold-plate.png'
import loraGoldPlate from '../images/logos/lora-logo-only-gold-plate.png'
import nbiotGoldPlate from '../images/logos/nbio-text-only-gold-plate.png'
import nordicGoldPlate from '../images/logos/nordic-semi-logo-gold-plate.png'
import sigFoxGoldPlate from '../images/logos/sigfox-gold-plate.png'
import stmGoldPlate from '../images/logos/stm-logo-gold-plate.png'
import nxpGoldPlate from '../images/logos/nxp-logo-gold-plate.png'
import { IoBatteryFullOutline, IoBluetoothOutline } from 'react-icons/io5'
import { BsGear, BsSoundwave, BsBroadcastPin } from 'react-icons/bs'
import { FaUsb, FaWaveSquare } from 'react-icons/fa'

const iconStyle = {className: "inline m-2 text-gold-plate", size: 30}

export const pngs = {
  "CAN Bus": canGoldPlate,
  "Ethernet": ethernetGoldPlate,
  "LORAWAN": loraGoldPlate,
  "NB IoT": nbiotGoldPlate,
  "Nordic": nordicGoldPlate,
  "SigFox": sigFoxGoldPlate,
  "ST Micro": stmGoldPlate,
  "NXP Semi": nxpGoldPlate,
}

export const icons = {
  "Battery Management": <IoBatteryFullOutline className={iconStyle.className} size={iconStyle.size}/>,
  "Bluetooth": <IoBluetoothOutline className={iconStyle.className} size={iconStyle.size}/>,
  "Control Systems": <BsGear className={iconStyle.className} size={iconStyle.size}/>,
  "USB": <FaUsb className={iconStyle.className} size={iconStyle.size}/>,
  "Signal Processing": <BsSoundwave className={iconStyle.className} size={iconStyle.size}/>,
  "RF Design": <BsBroadcastPin className={iconStyle.className} size={iconStyle.size}/>,
  "High speed": <FaWaveSquare className={iconStyle.className} size={iconStyle.size}/>
}