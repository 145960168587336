import React, {useEffect, useState} from "react";
import { Link, useLocation } from "react-router-dom";
import SectionHeader from "../components/SectionHeader";
import SectionDivider from "../components/SectionDivider";
import { IoChevronBackSharp } from "react-icons/io5";
import ProcessCard from "../components/ProcessCard";

function DevProcessFull() {

  const baseLayoutOption = {
    // "position1": { gridColumnStart: "1", gridColumnEnd: "2" },
    // "position2": { gridColumnStart: "2", gridColumnEnd: "3" },
    // "position3": { gridColumnStart: "3", gridColumnEnd: "4", display:"block" },
  }
  const smallLayoutOption = {
    "position1": { gridColumnStart: "1", gridColumnEnd: "4" },
    "position2": { gridColumnStart: "4", gridColumnEnd: "4" },
    "position3": { gridColumnStart: "2", gridColumnEnd: "5", display:"none",},
  }  

  const location = useLocation()
  const [layoutOption, setLayoutOption] = useState(baseLayoutOption)
  const [smallScreen, setSmallScreen] = useState(false)

  function handleResize(){
    if(window.innerWidth <= 768){
      setLayoutOption(smallLayoutOption)
      setSmallScreen(true)
    } else {
      setLayoutOption(baseLayoutOption)
      setSmallScreen(false)
    }
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(location.pathname === '/dev-process'){
      window.scrollTo(0,0)
    }
  }, [location])

  return (
    <div className="mt-8 sm:mt-20 mx-auto dev-process-container">
      <p className="underline underline-offset-2 font-bold ml-8">
        <Link to="/">
          <IoChevronBackSharp size={25} className="inline" /> Go Back
        </Link>
      </p>
      <section className="mt-8 md:mt-20">
        <SectionDivider text="Where I fit in" />
        <SectionHeader heading="Development Process" />
        <p className="text-lg my-4 w-full lg:w-2/3 2xl:w-1/2">
          Product development is a multi-disciplinary activity requiring collaboration of many skill sets.
          Having worked extensively in team environments, I’m comfortable slotting in exactly where you need me to be.
        </p>
        <p className="text-lg my-4 w-full lg:w-2/3 2xl:w-1/2">
          I'm comfortable slotting into an existing team as required, or helping to assemble a new team.
          I can reccommend and manage designers, mechanical engineers, and contract manufacturers to ensure a successful project.
        </p>
        <div id="process-grid" className="">
          <ProcessCard
            title="Product Specification"
            blurb="Engagement at the early stages of a project allows me to help highlight potential problem areas, help define timeline & effort expectations, as well as explore additional product feature possibilities."
            number="01"
            imgURL="product_specification.png"
            alignment={layoutOption}
            highlighted={true}
            swap={false}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="Industrial Design"
            blurb="Early involvement of industrial design is critical for products with user interfaces, and this deeply impacts the software and hardware requirements."
            number="02"
            imgURL="industrial_design.jpg"
            alignment={layoutOption}
            highlighted={false}
            swap={true}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="Mechanical Design"
            blurb="Close collaboration between electronic, software, and mechanical teams help ensure smooth physical product design that meets user needs clearly and effectively. Strong communication can highlight physical constraint issues early, avoiding costly PCB and enclosure redesigns."
            number="03"
            imgURL="mechanical_design.png"
            alignment={layoutOption}
            highlighted={false}
            swap={false}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="Schematic Capture"
            blurb="The first step in electronic design, which involves working to incorporate design and product requirements - including power usage, comms, interactivity and logging - into a suitable hardware system capable of supporting current (and some future) requirements. 
            The first step in electronic design involves component selection and configuration to design a system capable of supporting current (and some future) requirements."
            number="04"
            imgURL="can_master_kicad_small.jpg"
            alignment={layoutOption}
            highlighted={true}
            swap={true}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="PCB Design"
            blurb="Following on from schematic capture, PCB layout involves taking into consideration physical layout constraints, electrical clearances as well as usability considerations alongside mechanical and industrial design teams."
            number="05"
            imgURL="canmaster_pcb_small.jpg"
            alignment={layoutOption}
            highlighted={true}
            swap={false}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="Embedded Firmware"
            blurb="Write firmware and device drivers for microcontrollers to bring hardware to life. Just because the processor is small doesn’t mean that it can’t have complex behavior, ranging from sensing algorithms and motor control through to networking stacks."
            number="06"
            imgURL="uart_c_dark_small.jpg"
            alignment={layoutOption}
            highlighted={true}
            swap={true}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="Harware Support"
            blurb="With hardware and firmware passes complete, development moves into a hardware support and functionality verification stage, looking at troubleshooting and rectifying inevitable problem areas as they are identified. This extends to supporting downstream teams such as software developers and production staff."
            number="07"
            imgURL="hardware_support.png"
            alignment={layoutOption}
            highlighted={true}
            swap={false}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="Backend Integration"
            blurb="Many devices require connectivity to an internet backend or a PC application. Communications failures can occur at many places between the sensor and the database - and debugging them requires a broad skillset beyond just hardware and firmware. This is especially true for IoT systems, where the device interface is critical for low bandwidth systems."
            number="08"
            imgURL="backend_integration.jpg"
            alignment={layoutOption}
            highlighted={true}
            swap={true}
            smallScreen={smallScreen}
            lastItem={false}
          />
          <ProcessCard
            title="Manufacturing"
            blurb="I can assist in engaging with contract manufacturers to organize large scale production by providing guidance on part obsolescence / alternative component selection, generating documentation and building test fixtures for production verification. For smaller scale production runs I can assemble these myself."
            number="09"
            imgURL="manufacturing.jpg"
            alignment={layoutOption}
            highlighted={true}
            swap={false}
            smallScreen={smallScreen}
            lastItem={true}
          />
        </div>
      </section>
    </div>
  );
}

export default DevProcessFull;
