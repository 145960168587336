import React from "react";

function RadioCheckbox({ name, type, text, checked, handleRadioClick }) {

  return (
    <label id={name} className="radio-item mr-4" onChange={handleRadioClick}>
      <input
        id={`radio-${name}`}
        className="mr-2 "
        type="radio"
        // name={`radio-${name}`}
        name={`radio-${type}`}
        value={text}
        checked={checked}
        required
      />
      {/* <label className="mr-4 text-base" htmlFor={`radio-${name}`}> */}
        {text}
      {/* </label> */}
    </label>
  );
}

export default RadioCheckbox;
