import React from "react";

function ContactSocialMedia({ topline, bottomline, link, icon }) {
  return (
    <div className="mb-8 mr-4">
      <a href={`${link}`} target="_blank" rel="noreferrer">
        <div className="flex items-center">
          <div className="mr-4 p-2 bg-gold-plate rounded-lg border border-gold-highlight">
            {icon}
          </div>
          <div>
            <p className="text-xs">{topline}</p>
            <p className="text-sm font-bold">{bottomline}</p>
          </div>
        </div>
      </a>
    </div>
  );
}

export default ContactSocialMedia;
